import { request } from '@/util/http'

export const getList = (module, current, size, params) => {
  return request({
    url: module + '/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const exportExcel = (module, current, size, params) => {
  return request({
    url: module + '/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

export function importExcel (module, file) {
  const data = new FormData()
  data.append('file', file.raw)
  return request({
    url: module + '/import',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export const getDetail = (module, id) => {
  return request({
    url: module + '/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (module, ids) => {
  return request({
    url: module + '/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (module, row) => {
  return request({
    url: module + '/submit',
    method: 'post',
    data: row
  })
}
export const modelExcel = (module, current, size, params) => {
  return request({
    url: module + '/model',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getSts = (bucket) => {
  return request({
    url: '/oss/sts',
    method: 'get',
    params: {
      bucket
    }
  })
}

