<template>
  <div class="vue-top">
    <div class="top-bar__left">
      <div
          v-if="!useSecMenu"
          class="vue-breadcrumb"
          :class="[{ 'vue-breadcrumb--active': isCollapse }]">
        <i
            class="icon-navicon"
            @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__title">
      <span
          class="top-bar__item">
        <top-search></top-search>
      </span>
    </div>
    <div class="top-bar__right">
      <el-tooltip
          effect="dark"
          content="主题"
          placement="bottom">
        <div class="top-bar__item top-bar__item--show">
          <top-theme></top-theme>
        </div>
      </el-tooltip>
      <img
          v-if="userInfo.avatarUrl"
          class="top-bar__img"
          :src="userInfo.avatarUrl">
      <img
          v-else
          class="top-bar__img"
          src='~@/assets/img/avatar.png'>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ userInfo.name || '用户' }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <router-link to="/index">首页</router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <router-link to="/user/info">个人信息</router-link>
          </el-dropdown-item>
          <el-dropdown-item
              @click.native="logout"
              divided>退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { validateNull } from '@/util/validate'
import website from '@/config/website'
import topTheme from './top-theme'
import topSearch from './top-search'

export default {
  components: {
    topTheme, topSearch
  },
  name: 'Top',
  data () {
    return {
      useSecMenu: website.useSecMenu
    }
  },
  filters: {},
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({}),
    ...mapGetters([
      'userInfo',
      'isCollapse'
    ])
  },
  methods: {
    validateNull (val) {
      return validateNull(val)
    },
    setCollapse () {
      this.$store.commit('SET_COLLAPSE')
    },
    goInfo () {
      this.$router.push('/')
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$store.commit('DEL_ALL_TAG')
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar__right {
  span {
    font-size: 15px;
    margin-right: 5px;
  }

}
</style>
