import Vue from 'vue'
import { getToken } from '@/util/auth'
import store from '../store'
import VueRouter from 'vue-router'
import PageRouter from './page/'
import ViewsRouter from './views/'
import vueRouter from './vue-router'
import { validateNull } from '@/util/validate'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })
const PUBLIC_PATH = process.env.VUE_APP_CONTEXT_PATH || '/'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export const createRouter = () => new VueRouter({
  mode: 'history',
  base: PUBLIC_PATH,
  routes: [...PageRouter, ...ViewsRouter]
})

const router = createRouter()
vueRouter.install(Vue, router, store)
router.$vueRouter.formatRoutes(store.state.user.menu, true)

// 重置路由
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  vueRouter.install(Vue, router, store)
}

const whiteList = ['/login'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  const code = to.query.code
  const token = getToken()
  if (code) {
    if (token) {
      next({ path: '/user/info', query: { codes: code }})
    } else {
      next({ path: '/login', query: { codes: code }})
    }
  } else {
    if (token) {
      // 如果已经登录，则直接跳转到首页
      if (to.path === '/login') {
        next('/')
      }
      // 如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
      if (store.getters.token.length === 0) {
        next({ path: '/login' })
      } else {
        // 否则放行
        const value = to.query.src || to.fullPath
        const label = to.query.name || to.name
        const meta = to.meta || router.$vueRouter.meta || {}
        if (meta.isTab !== false && !validateNull(value) && !validateNull(label)) {
          store.commit('ADD_TAG', {
            label: label,
            value: value,
            params: to.params,
            query: to.query,
            meta: meta,
            group: router.$vueRouter.group || []
          })
        }
        next()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next('/login')
        NProgress.done()
      }
    }
  }
})

router.afterEach((to, from) => {
  NProgress.done()
  const title = to.query.name || to.name
  // 根据当前的标签也获取label的值动态设置浏览器标题
  router.$vueRouter.setTitle(title)
})

export default router
