import website from '@/config/website'

import { getStore, setStore } from '@/util/storage'
import { isNotNull } from '@/util'
import Vue from 'vue'
import { getSts } from '@/api/common'
import { baseUrl } from '@/config'

const oss = process.env.VUE_APP_OSS

const common = {
  state: {
    menuDefault: website.menuDefault,
    isCollapse: false,
    screen: -1,
    isRefresh: true,
    themeName: getStore({ name: 'themeName' }) || 'theme-default',
    website: website
  },
  mutations: {
    SET_IS_REFRESH: (state, refresh) => {
      state.isRefresh = refresh
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen
    },
    SET_COLLAPSE: (state, isCollapse) => {
      if (isNotNull(isCollapse)) {
        state.isCollapse = isCollapse
      } else {
        state.isCollapse = !state.isCollapse
      }
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName
      setStore({ name: 'themeName', content: state.themeName })
    }
  },
  actions: {
    // 根据用户名登录
    loadAli ({ commit }, bucket, prefix = 'file') {
      return new Promise(() => {
        if (oss === 'minio') {
          Vue.prototype.$AVUE.minio = {
            url: baseUrl + '/minio/url',
            bucket: bucket,
            prefix: prefix
          }
        } else {
          getSts(bucket).then(res => {
            const data = res.data
            Vue.prototype.$AVUE.ali = {
              region: 'oss-cn-shanghai',
              endpoint: 'oss-cn-shanghai.aliyuncs.com',
              accessKeyId: data.accessKeyId,
              accessKeySecret: data.accessKeySecret,
              stsToken: data.securityToken,
              bucket: bucket
            }
          })
        }
      })
    }
  }
}
export default common
