import { setToken } from '@/util/auth'
import { setStore, getStore, removeStore } from '@/util/storage'
import { loginByUsername, getUserInfo, loginByPhone, loginByOpen } from '@/api/login'
import { tree } from '@/api/sys/menu'
import { deepClone } from '@/util'
import website from '@/config/website'
import { resetRouter } from '@/router'

const user = {
  state: {
    userInfo: getStore({ name: 'userInfo' }) || [],
    permission: getStore({ name: 'permission', type: 'session' }) || {},
    menu: getStore({ name: 'menu', type: 'session' }) || [],
    token: getStore({ name: 'token', type: 'session' }) || ''
  },
  actions: {
    // 根据用户名登录
    LoginByUsername ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo).then(res => {
          if (res.success) {
            const data = res.data
            commit('SET_TOKEN', data)
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    // 微信扫码登录
    LoginByOpen ({ commit }, value) {
      return new Promise((resolve, reject) => {
        loginByOpen(value.code, value.appId).then(res => {
          if (res.success) {
            const data = res.data
            commit('SET_TOKEN', data)
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    // 根据手机号登录
    LoginByPhone ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByPhone(userInfo).then(res => {
          const data = res.data
          commit('SET_TOKEN', data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    GetUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) => {
          const data = res.data
          commit('SET_USER_INFO', data)
          commit('SET_PERMISSION', data.permissions)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    // 注销session
    LogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_USER_INFO', {})
        commit('SET_PERMISSION', [])
        commit('SET_MENU', [])
        commit('SET_TOKEN', undefined)
        commit('DEL_ALL_TAG')
        website.sessionStorageKey.forEach(key => {
          removeStore({ name: key, type: 'session' })
        })

        removeStore({ name: 'userInfo' })
        resetRouter()
        resolve()
      })
    },

    // 获取系统菜单
    GetMenu ({ commit, dispatch }) {
      return new Promise(resolve => {
        tree(1).then((res) => {
          const data = res.data
          const menu = deepClone(data)
          commit('SET_MENU', menu)
          dispatch('GetUserInfo')
          resolve(menu)
        })
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({ name: 'menu', content: state.menu, type: 'session' })
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = {}
      permission.forEach(ele => {
        state.permission[ele] = true
      })
      setStore({ name: 'permission', content: state.permission, type: 'session' })
    }
  }

}
export default user
