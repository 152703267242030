import Layout from '@/page/index/'

export default [{
  path: '/',
  component: Layout,
  redirect: '/index',
  children: [{
    path: 'index', name: '首页', component: () => import('@/views/index')
  }, {
    path: '/user/info',
    name: '个人信息',
    meta: {
      keepAlive: false
    },
    component: () => import('@/views/user/info')
  }, {
    path: '/mall/goodstraceedit',
    name: '溯源编辑',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/user/info')
  }

  ]
}, {
  path: '/work/process/repairs',
  component: Layout,
  redirect: '/work/process/repairs/form',
  children: [{
    path: 'form/:processDefinitionId',
    name: '报修申请',
    component: () => import('@/views/work/process/repairs/form')
  }, {
    path: 'handle/:taskId/:processInstanceId/:businessId',
    name: '报修流程',
    component: () => import('@/views/work/process/repairs/handle')
  }, {
    path: '/:taskId',
    name: '报修详情',
    component: () => import('@/views/work/process/repairs/detail')
  }]
}]
