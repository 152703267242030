const title = '智慧农贸平台'

const baseUrl = process.env.VUE_APP_BASE_URL
const flowUrl = baseUrl + '/flow/process/diagram-view?processInstanceId='
const flowUIUrl = 'http://localhost:7081'

const wxOpenKey = 'wx06d8b8cb5c9b11b9'
const wxOpenUrl = 'https://plat.zcondigit.com'

const oss = process.env.VUE_APP_OSS || 'ali'

export {
  flowUrl,
  flowUIUrl,
  title,
  wxOpenKey,
  wxOpenUrl,
  baseUrl,
  oss
}
