import { get, post, request } from '@/util/http'

export const loginByUsername = (dto) =>
  post('/anon/login', dto)

export const getUserInfo = () => get('/sys/user/info')

export const refreshToken = () => post('/user/refresh')

export const getTopMenu = () => get('/user/getTopMenu')

export const sendLogs = (list) => post('/user/logout', list)

export const sendSms = (mobile, type) => get('/anon/sms', { mobile, type })

export const passwordSms = (dto) =>
  post('/anon/passwordSms', dto)

export const loginByPhone = (dto) => post('/anon/login/sms', dto)

export const loginByOpen = (code, appId) => {
  return request({
    url: '/anon/login/open',
    method: 'post',
    params: { code, appId }
  })
}

